import { generateUUID } from "@/helpers/uuid";
import { Option } from "@/models/class/option.class";
import { EnumSparePartRequestStatus } from "@/models/enums/SparePartRequest.enum";
import { LabelInValue } from "@/types";
import moment, { Moment } from "moment";
import { AssetResponseDto } from "../master-asset";

export class SparePartRequestFormPartState {
  key: string = generateUUID();
  imageUrl = "";
  partRef = "";
  uomRef = "";
  quantity = 0;
  sparePartItemId = "";
  disabled = false;
}

export class SparePartRequestFormUnitState {
  key: string = generateUUID();
  brand: string | undefined;
  brandRef: string | undefined;
  equipment: string | undefined;
  model: string | undefined;
  parts: SparePartRequestFormPartState[] = [];
  serialNumber: string | undefined;
  spec: string | undefined;
  type: string | undefined;
  typeRef: string | undefined;
  unitCode: LabelInValue | undefined;
  unitCodeRef: string | undefined;
  location: string | undefined;
  sparePartUnitId = "";

  unitCodeOptions: Option<AssetResponseDto>[] = [];
  unitCodeLoading = false;
  useLineOptions = false;
}

export class SparePartRequestFormState {
  addressRef: string | undefined;
  customer: LabelInValue | undefined;
  customerRef: string | undefined;
  deletedUnitIds: string[] = [];
  deletedPartIds: string[] = [];
  description: string | undefined;
  documentNo!: string;
  mechanic: LabelInValue | undefined;
  requestDate: Moment | null = moment();
  status!: EnumSparePartRequestStatus;
  units: SparePartRequestFormUnitState[] = [];
  branch: LabelInValue | undefined;
}
