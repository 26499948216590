export class SparePartRequestPartRefState {
  partName = "";
  qty = 0;
}

export class SparePartRequestRefUnitState {
  brand = "";
  type = "";
  unitCode = "";
  parts: SparePartRequestPartRefState[] = [];
}

export class SparePartRequestRefState {
  address = "";
  customer = "";
  units: SparePartRequestRefUnitState[] = [];
}
