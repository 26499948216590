import { DownloadRequestParam } from "@/models/interface/download";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  CreateSparePartRequestDto,
  CreateSparePartRequestPartDto,
  CreateSparePartRequestUnitDto,
  CreateWorkOrderFormState,
  CreateWorkOrderSparePartFormState,
  CreateWorkOrderUnitFormState,
  SparePartDetailHeaderResDto,
  SparePartRequestFormPartState,
  SparePartRequestFormState,
  SparePartRequestFormUnitState,
  SparePartRequestPartRefState,
  SparePartRequestRefState,
  SparePartRequestRefUnitState,
} from "@/models/interface/sparepart-request";
import {
  WorkOrderCreateRequestDto,
  WorkOrderSparePartRequestDto,
  WorkOrderUnitRequestDto,
} from "@/models/interface/work-order";
import store from "@/store";
import moment from "moment";

export class SparePartRequestMapper {
  static mapDownloadParams(
    param: RequestQueryParamsModel,
    {
      documentNo,
      mechanicName,
      requestStartDate,
      requestEndDate,
      status,
      branchName,
    }: {
      documentNo?: string;
      mechanicName?: string;
      requestStartDate?: string;
      requestEndDate?: string;
      status?: string;
      branchName?: string;
    }
  ): DownloadRequestParam {
    const DEFAULT = "All";

    const company = store.state["authStore"].authData.companyName;
    const filters: string[] = [
      company || DEFAULT,
      documentNo || DEFAULT,
      mechanicName || DEFAULT,
      requestStartDate || DEFAULT,
      requestEndDate || DEFAULT,
      status || DEFAULT,
      branchName || DEFAULT,
    ];

    return {
      ...param,
      params: filters.join(","),
    };
  }

  static toSparePartRequestFormState(
    dto: SparePartDetailHeaderResDto
  ): SparePartRequestFormState {
    const state: SparePartRequestFormState = new SparePartRequestFormState();
    state.addressRef = dto.addressRef;
    state.customerRef = dto.customerRef;
    state.documentNo = dto.documentNo;
    state.status = dto.status;
    state.requestDate = dto.requestDate ? moment(dto.requestDate) : null;
    if (dto.customerId && dto.customerName) {
      state.customer = { key: dto.customerId, label: dto.customerName };
    }
    state.description = dto.description;

    if (dto.branchId && dto.branchName) {
      state.branch = {
        key: dto.branchId,
        label: dto.branchName,
      };
    }

    if (dto.mechanicId && dto.mechanicName) {
      state.mechanic = { key: dto.mechanicId, label: dto.mechanicName };
    }

    state.units = dto.units.map(unit => {
      const unitState = new SparePartRequestFormUnitState();
      unitState.brandRef = unit.brandRef;
      unitState.unitCodeRef = unit.unitCodeRef;
      unitState.typeRef = unit.typeRef;
      unitState.sparePartUnitId = unit.sparePartUnitId;

      if (unit.assetId && unit.unitCode) {
        unitState.unitCode = { key: unit.assetId, label: unit.unitCode };
      }
      unitState.parts = unit.items.map(part => {
        const partState = new SparePartRequestFormPartState();
        partState.partRef = part.partName;
        partState.quantity = part.qty;
        partState.sparePartItemId = part.sparePartItemId;
        partState.imageUrl = part.attachmentFile;
        partState.uomRef = part.uomReference;

        return partState;
      });

      return unitState;
    });

    return state;
  }

  static toSparePartRequestFormRefState(
    dto: SparePartDetailHeaderResDto
  ): SparePartRequestRefState {
    const state = new SparePartRequestRefState();
    state.address = dto.addressRef;
    state.customer = dto.customerRef;
    state.units = dto.units.map(unit => {
      const unitRef = new SparePartRequestRefUnitState();
      unitRef.brand = unit.brandRef;
      unitRef.type = unit.typeRef;
      unitRef.unitCode = unit.unitCodeRef;
      unitRef.parts = unit.items.map(part => {
        const partRef = new SparePartRequestPartRefState();
        partRef.partName = part.partName ?? "";
        partRef.qty = part.qty ?? 0;
        return partRef;
      });
      return unitRef;
    });
    return state;
  }

  static toCreateDto(
    state: SparePartRequestFormState
  ): CreateSparePartRequestDto {
    const request: CreateSparePartRequestDto = {
      address: state.addressRef ?? "",
      customerId: state.customer?.key ?? "",
      branchId: state.branch?.key ?? "",
      deletedUnitIds: state.deletedUnitIds,
      deletedItemIds: state.deletedPartIds,
      description: state.description ?? null,
      documentNo: state.documentNo,
      mechanicId: state.mechanic?.key ?? "",
      requestDate: state.requestDate?.format() ?? "",
      unitRequestDTOLines: state.units.map(unit => {
        const unitDto: CreateSparePartRequestUnitDto = {
          itemRequestDTOLines: unit.parts.map(part => {
            const partDto: CreateSparePartRequestPartDto = {
              partName: part.partRef,
              qty: part.quantity,
              sparePartItemId: part.sparePartItemId,
              uomReference: part.uomRef,
            };
            return partDto;
          }),
          sparePartUnitId: unit.sparePartUnitId,
          unitCode: unit.unitCode?.label ?? "",
        };
        return unitDto;
      }),
    };

    return request;
  }

  static mapDetailToCreateWorkOrderFormState(
    detail: SparePartDetailHeaderResDto
  ): CreateWorkOrderFormState {
    const state: CreateWorkOrderFormState = {
      scheduleOrderDate: null,
      workOrderDate: null,
      spareParts: [],
      units: [],
      description: detail.description || "",
      documentNumber: detail.documentNo,
    };

    if (detail.customerId) {
      state.customer = {
        key: detail.customerId,
        label: detail.customerName,
      };
    }

    if (detail.branchId) {
      state.branch = {
        key: detail.branchId,
        label: detail.branchName,
      };
    }

    if (detail.mechanicId) {
      state.mechanic = {
        key: detail.mechanicId,
        label: detail.mechanicName,
      };
    }

    return state;
  }

  static mapCreateWorkOrderUnitToCreateDto = (
    form: CreateWorkOrderUnitFormState
  ): WorkOrderUnitRequestDto => {
    return {
      assetId: form.assetId ?? "",
      unitCode: form.unitCode ?? "",
      unitSpecification: form.specification ?? "",
    };
  };

  static mapCreateWorkOrderSparePartToCreateDto = (
    form: CreateWorkOrderSparePartFormState
  ): WorkOrderSparePartRequestDto => {
    return {
      notes: form.notes ?? "",
      partNumber: form.partNumber?.label ?? "",
      productId: form.partNumber?.key ?? "",
      productLocationId: form.partLocation?.key ?? "",
      productUomId: form.uom?.key ?? "",
      qtyWorkOrder: form.qty,
      unitCode: form.unitCode ?? "",
      sparePartRequestItemId: form.sparePartRequestItemId ?? "",
    };
  };

  static mapCreateWorkOrderFormStateToRequestDto(
    form: CreateWorkOrderFormState,
    sparePartRequestId: string
  ): WorkOrderCreateRequestDto {
    return {
      branchWarehouseId: form.branch?.key ?? "",
      customerAddress: form.customerAddress?.key ?? "",
      customerId: form.customer?.key ?? "",
      description: form.description ?? "",
      mechanicId: form.mechanic?.key ?? "",
      picName: form.customerPICName ?? "",
      scheduleOrder: form.scheduleOrderDate?.format() ?? "",
      spareParts: form.spareParts.map(
        this.mapCreateWorkOrderSparePartToCreateDto
      ),
      units: form.units.map(this.mapCreateWorkOrderUnitToCreateDto),
      workOrderDate: form.workOrderDate?.format() ?? "",
      docReference: form.documentNumber ?? "",
      sparePartRequestId: sparePartRequestId,
    };
  }
}
